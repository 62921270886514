export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_SOURCES_SUCCESS = 'GET_SOURCES_SUCCESS';
export const GET_CLIENTS_EXTENSIVE = 'GET_CLIENTS_EXTENSIVE';
export const GET_SOURCE_CLIENT = 'GET_SOURCE_CLIENT';
export const UPDATE_CLIENT_SEARCH_FORM = 'UPDATE_CLIENT_SEARCH_FORM';
export const GET_CLIENTS_SEARCH = 'GET_CLIENTS_SEARCH';
export const GET_ORDERS_CLIENT = 'GET_ORDERS_CLIENT';
export const UPDATE_FORM_CLIENTS = 'UPDATE_FORM_CLIENTS';
export const UPDATE_FORM_ADD_CLIENT = 'UPDATE_FORM_ADD_CLIENT';
export const CLEAR_FORM_ADD_CLIENT = 'CLEAR_FORM_ADD_CLIENT';
export const UPATE_FORM_CLIENT_BONUS = 'UPATE_FORM_CLIENT_BONUS';
export const UPATE_FORM_CLIENT_BONUS_CLEAR = 'UPATE_FORM_CLIENT_BONUS_CLEAR';
export const UPATE_FORM_CLIENT_BONUS_WRITE = 'UPATE_FORM_CLIENT_BONUS_WRITE';
export const CALLS_UPDATE_ISLOAD = "CALLS_UPDATE_ISLOAD";
export const CALLS_UPDATE_FILTER = "CALLS_UPDATE_FILTER";
export const CALLS_GET_LIST_CALLS = "CALLS_GET_LIST_CALLS";
export const SET_MODAL_CLIENT_INFO_LOADING = "SET_CLIENT_INFO_LOADING";
export const OPEN_MODAL_CLIENT_INFO = "OPEN_MODAL_CLIENT_INFO";
export const CLOSE_MODAL_CLIENT_INFO = "CLOSE_MODAL_CLIENT_INFO";
export const UPDATE_MODAL_CLIENT_INFO_DATA = "UPDATE_MODAL_CLIENT_INFO_DATA";