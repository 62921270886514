import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import classNames from "classnames";

import Loader from "../Spiner";

import styles from './Select.module.scss';
import requiredIf from 'react-required-if';

const selectStyles = {
  primary: {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#eda240' : '#425466',
      backgroundColor: '#FFFFFF',
      fontSize: 14
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF'
    }),
    container: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      minHeight: 42
    }),
    control: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      minHeight: 42,
      border: '1px solid #DEDEDE'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#425466',
      fontSize: 14
    }),
    input: provided => ({
      ...provided,
      color: '#252525'
    })
  },
  secondary: {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#eda240' : '#F0F0F0',
      backgroundColor: '#2d303e',
      fontSize: 14
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#2d303e'
    }),
    container: provided => ({
      ...provided,
      backgroundColor: '#2d303e',
      borderRadius: '10px',
      minHeight: 42
    }),
    control: provided => ({
      ...provided,
      backgroundColor: '#2d303e',
      borderRadius: '10px',
      minHeight: 42,
      border: '0'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#FFFFFF',
      fontSize: 14
    }),
    input: provided => ({
      ...provided,
      color: '#FFFFFF'
    })
  }
};
function CustomSelect({
  selectOptions,
  selectedOption,
  inputValue,
  onChange,
  onInputChange,
  valueKey,
  isShowError,
  isSearchable,
  isClearable,
  isLoading,
  error,
  label,
  mode,
  placeholder,
  isMulti,
  disabled = false,
  isLoad = false,
  noMargin = false,
  async = false,
  creatable = false,
  // async select props
  cacheOptions,
  defaultOptions,
  loadOptions,
  // creatable select props
  onCreateOption,
  //
  loaderProps,
}) {
  if (inputValue) {
    console.error('Проп inputValue було вимкнено для сумісності з React-select autocomplete. Ця помилка була викликана в компоненті Select (ui-kit/Select)')
  }

  const handleChange = option => {
    onChange({ valueKey, value: option });
  };

  const handleInputChange = !async ? value => {
    onInputChange({ valueKey, value });
  } : undefined;

  const handleBlur = !async ? () => {
    if (!isSearchable) {
      return;
    }

    const isOptions = !!selectOptions.length;

    if (!isOptions) {
      onChange({ valueKey, value: { value: '', label: inputValue, name: '' } });
    }
  } : undefined;

  const getErrorClassName = () => {
    const className = error
      ? `${styles.select__error_visible}`
      : styles.select__error;

    return className;
  };

  const SelectComponent = React.useMemo(() => {
    if (async && creatable)
      return AsyncCreatableSelect;
    if (async)
      return AsyncSelect;
    if (creatable)
      return CreatableSelect;
    return Select;
  }, [async, creatable]);

  return (
    <div className={classNames(styles.select, styles[mode], {
      [styles.no_margin]: noMargin,
    })}>
      {label && (
        <p className={`${styles.select__label}`}>{label}</p>
      )}

      <SelectComponent
        options={selectOptions}
        value={selectedOption}
        placeholder={placeholder}
        // inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isSearchable={isSearchable || creatable}
        isClearable={isClearable}
        styles={selectStyles[mode]}
        isLoading={isLoading}
        isMulti={isMulti}
        onBlur={handleBlur}
        isDisabled={disabled}
        // pass async options
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        // pass creatable options
        onCreateOption={onCreateOption}
      />

      {
        isShowError && (
          <p className={getErrorClassName()}>
            {error || '.'}
          </p>
        )
      }

      {
        isLoad && (
          <div className={styles.load_wr}>
            <Loader {...loaderProps} />
          </div>
        )
      }

    </div>
  );
}

CustomSelect.propTypes = {
  selectOptions: requiredIf(PropTypes.array, (props) => !props.async),
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  isShowError: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  mode: PropTypes.oneOf(['primary', 'secondary']),
  isMulti: PropTypes.bool
};

CustomSelect.defaultProps = {
  label: '',
  inputValue: '',
  isShowError: false,
  isSearchable: false,
  isLoading: false,
  selectedOption: {},
  error: '',
  mode: 'secondary',
  onInputChange: () => { },
  isMulti: false
};

export default React.memo(CustomSelect);
