import React from "react";
import styled, { useTheme } from "styled-components";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import ruLocale from 'date-fns/locale/ru';

const views = ["hours", "minutes"];
const slotProps = { textField: {} };

const TimeInputComponent = ({ title, value, valueKey, onChange, width, min, max, minutesStep=5, isStyle = false, error = false }) => {
  const theme = useTheme();
  const onTimePickerChange = React.useCallback((time) => {
    return time.isValid() && onChange(time, valueKey);
  }, [onChange, valueKey]);

  return (
    <Wrapper theme={theme} width={width} locale={ruLocale}>
      <Title isStyle={isStyle}>{title}</Title>
      <Label isStyle={isStyle} error={error}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            ampm={false}
            openTo="hours"
            views={views}
            value={value}
            onChange={onTimePickerChange}
            minTime={min}
            maxTime={max}
            minutesStep={minutesStep}
            slotProps={slotProps}
          />
        </LocalizationProvider>
      </Label>
    </Wrapper>
  )
}

export const TimeInput = React.memo(TimeInputComponent);

const Wrapper = styled.div`
  width: ${(p) => p.width};
  margin: 3px;
  padding-top: 4px;
`;

const Title = styled.span`
  margin-left: 4px;
  border-radius: 3px;
  color: ${(p) => !p.isStyle ? p.theme.secondaryColor : "#000"};
  font-size: 14px;
`;

const Field = styled.input`
  width: 100%;
  margin-top: 4px;
  padding: 8px;
  background-color: ${(p) => p.theme.backgroundLight};
  border: ${(p) => p.theme.inputBorder};
  border-radius: 8px;
  color: ${(p) => p.theme.secondaryColor};
  font-size: 18px;
  font-family: Rubik;
  outline: none;
  &:active,
  &:focus {
    border: 1px solid ${(p) => p.theme.accentColor};
  }
`;

const Label = styled.label`
  position: relative;

  .MuiFormControl-root {
    margin-top: 5px;
    position: relative;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: ${(p) => p.error ? p.theme.inputBorderError : (!p.isStyle ? p.theme.inputBorder : p.theme.inputBorderDE)};
    background-color:  ${(p) => !p.isStyle ? p.theme.backgroundLight : "#FFFFFF"};
    color: ${(p) => !p.isStyle ? p.theme.secondaryColor : "#000"};
    box-shadow: none;
    outline: none;
    height: 42px;
    &:active,
    &:focus {
      border: 1px solid ${(p) => p.theme.accentColor};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none!important;
  }

  .Mui-focused {
    border: 1px solid ${(p) => p.theme.accentColor};
  }

  svg {
    fill: ${(p) => !p.isStyle ? "#fff" : p.theme.inputBorderDE};
  }

  // .MuiButtonBase-root {
  //   position: inherit;
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 8px;
  //   }
  // }
`;