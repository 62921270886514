import Base from "./Base.js";

export default class TagsAPI extends Base {
  createTag(data) {
    return this.apiClient.post("tags", data);
  }

  searchTags(query, limit) {
    return this.apiClient.get("tags/search", { query, limit });
  }
}
