import { connect } from "react-redux";
import PageCommon from "./Common";

// actions
import { 
  updateSiteSettingsFormBrands,
  getSiteSettingsFormBrands,
  submitSiteSettingsFormBrands,
} from "store/actions/settings";

const mapStateToProps = (state) => ({
  isLoadPage: state.view.isLoadPage,
  isLoadEdit: state.view.isLoadEditSiteSettingsBrands,
  restaurant: state.settings.sites.selectedRestaurant,
  data: state.settings.sites.brandSettingsForm,
});

const mapDispatchToProps = {
  updateSiteSettingsFormBrands,
  getSiteSettingsFormBrands,
  submitSiteSettingsFormBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCommon);
