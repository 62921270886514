import { useState, useCallback } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Checkbox from "components/ui-kit/Checkbox";
import Radio from "components/ui-kit/Radio";
import InputImage from "components/ui-kit/InputImage";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
// import AddIcon from "@mui/icons-material/Add";

import styles from "../scss/settings.module.scss";

import { RADIO_SCHEME_BANNER_TYPE } from "constants/schemes";
import { DAYS as days } from "constants/schemes";

// actions
import { updateItemLoadByName } from 'store/actions/view';

export const BannerEdit = ({
  data,
  view,
  onSubmit,
  onSubmitUpdate,
  handleSubmitClose,
  updateFormBanners,
  uploadImage,
}) => {
  const [error] = useState({});

  const titleButton = data?.id ? "Змінити" : "Додати";
  const title = data?.id ? `Редагування ${data.title}` : "Створення нового";

  const handlerSubmit = useCallback(() => {
    if (data?.id) {
      onSubmitUpdate(data?.id);
    } else {
      onSubmit();
    }
  }, [data?.id, onSubmit, onSubmitUpdate]);

  const handleBannerImageUpload = useCallback((objectFile) => {
    if (!objectFile) return;
    const setLoading = updateItemLoadByName.bind(null, "isLoadEditBanners");

    const formData = new FormData();

    formData.append("type", "banner");
    formData.append("image", objectFile, objectFile.name);

    uploadImage(formData, ({ file_path: filePath }) => {
      updateFormBanners("image", filePath);
    }, setLoading);
  }, [uploadImage, updateFormBanners]);

  const handleDayOfWeekClicked = useCallback((value) => {
    const current = data.settings?.days_of_the_week ?? [];
    const path = "settings.days_of_the_week";

    const valueIndex = current.indexOf(value);
    if (valueIndex !== -1) {
      return updateFormBanners(path, current.filter((day) => day !== value));
    }

    updateFormBanners(path, [...current, value]);

  }, [data.settings?.days_of_the_week]);

  return (
    <div className={styles.row_mrt}>
      <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
      <div className={styles.wr_drop}>
        <Radio
          colors="#000000"
          items={RADIO_SCHEME_BANNER_TYPE}
          label="Тип"
          valueKey="type"
          value={data.settings.type}
          onChange={({ value }) => updateFormBanners("settings.type", value.value)}
        />
      </div>
      <div className={styles.wr_drop}>
        <InputImage
          currentImage={data.image}
          manualChangeCallback={handleBannerImageUpload}
          classNames={[styles.banner_select]}
        />
      </div>
      <div className={styles.wr_drop}>
        <TextInput
          title="Заголовок"
          type="text"
          value={data.title}
          isStyle={true}
          error={error.title}
          onChange={(e) => updateFormBanners("title", e.target.value)}
        />
      </div>
      <div className={styles.wr_drop}>
        <TextInput
          title="Підзаголовок"
          type="text"
          value={data.subtitle}
          isStyle={true}
          error={error.subtitle}
          onChange={(e) => updateFormBanners("subtitle", e.target.value)}
        />
      </div>
      <div className={styles.wr_drop}>
        <TextInput
          title="Посилання"
          type="text"
          value={data.link}
          isStyle={true}
          error={error.link}
          onChange={(e) => updateFormBanners("link", e.target.value)}
        />
      </div>
      <div className={styles.wr_drop}>
        <TextInput
          title="Текст кнопки"
          type="text"
          value={data.button_text}
          isStyle={true}
          error={error.button_text}
          onChange={(e) => updateFormBanners("button_text", e.target.value)}
        />
      </div>
      <div className={styles.wr_drop}>
        <div className={styles.title_input}>День тижня</div>
        <div className={styles.items_chec}>
          {days.map((item, index) => {
            const value = (index + 1).toString();
            return (
              <Checkbox
                key={index}
                title={item.title}
                value={value}
                checked={data.settings?.days_of_the_week?.includes(value)}
                onChecked={handleDayOfWeekClicked}
                isStyle
              />
            )
          })}
        </div>
      </div>
      <div className={styles.row_btn_tow}>
        <div className={styles.item_btn}>
          <ButtonIcon
            onClick={handlerSubmit}
            title={titleButton}
            colorBg="green"
            icon={<SaveIcon />}
          />
        </div>
        <div className={styles.item_btn}>
          <ButtonIcon
            onClick={handleSubmitClose}
            icon={<CloseIcon />}
          />
        </div>
      </div>
      {
        view.isLoadEditBanners && (
          <LoadingModal />
        )
      }
    </div>
  )
}

