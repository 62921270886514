import api from "../../apiSingleton";
import {
  GET_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FETCHING,
  UPDATE_PRODUCTS_SEARCH,
  GET_PRODUCT_BY_ID,
  RESET_FORM_PRODUCT,
  UPDATE_FORM_PRODUCT,
  UPDATE_FORM_PRODUCT_TYPE_PRICE,
  CREATE_FORM_PRODUCT_PRICES_ENTRY,
  DELETE_FORM_PRODUCT_PRICES_ENTRY,
  UPDATE_FORM_PRODUCT_COMBO_SLOTS,
  UPDATE_MODAL_PRODUCT_SPECIFICATION,
} from "../constants/products";

import { updateLoadModalProducts, updadeLoadPageClients, updateItemLoadByName } from "./view";

import {
  addNotification
} from './notifications';

const DEFAULT_PARAMS = {
  restaurant: "go",
  per_page: 30,
};

export function getProducts(params = DEFAULT_PARAMS, setFetching = () => { }, setCurrentPage = () => { }, isNewStatus = false,) {
  return async (dispatch) => {
    await dispatch(updateProductsFetching(true));

    await dispatch(updateLoadModalProducts(true));

    let parame = params.short_projection ? 
      params : 
      {
        per_page: "40",
        page: "1",
        ...params,
      };

    let isPage = false;
    if (!!parame?.isPage) {
      isPage = true;
      delete parame.isPage;
    }

    const res = await api.products.getList({
      ...parame
    });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      setCurrentPage(res.meta?.current_page || 1);
      await dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: {
          data: res.data,
          meta: res.meta,
          isNewStatus: isNewStatus || isPage,
          isRecord: params == false ? true : false
        },
      });
    }
    await dispatch(updateProductsFetching(false));
    await dispatch(updateLoadModalProducts(false));
    setFetching(false);
  };
}

export function createProduct(product, image = undefined, backs = () => { }) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    let res = await api.products.createProduct({
      ...product,
      category_sync_id: product.category?.sync_id || product.category_sync_id,
    });

    if (image && res?.id) {
      await updateProductImage({ id: res.id, image });
    }

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ successfull: ["Створено"] }));
      await dispatch(getProducts());
      backs();
    }

    await dispatch(updadeLoadPageClients(false));
  }
}

export function updateProduct(product, image = undefined, backs = () => { }) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    let res = await api.products.updateProduct(product, product.id);

    if (image) {
      await updateProductImage({ id: product.id, image });
    }

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ successfull: ["Оновлено"] }));
      await dispatch(getProducts());
      backs();
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

/* export */ function updateModalProductSpecification(payload) {
  return {
    type: UPDATE_MODAL_PRODUCT_SPECIFICATION,
    payload: payload,
  }
}

export function closeModalProductSpecification() {
  return updateModalProductSpecification({ 
    isOpen: false,
  });
}

export function getProductSpecification({ article, restaurant }) {
  return async (dispatch, state) => {

    dispatch(updateModalProductSpecification({ isOpen: true, isLoad: true }));

    const { success, data } = await api.products.getProductSpecification(article);
    if (success) {
      const [ { specific: specification } ] = data;
      const { name, Items: items } = specification;

      return dispatch(updateModalProductSpecification({ 
        specification: { name, items, restaurant },
        isLoad: false 
      }));
    }

  }
}

export function updateProductsSearchQuery(searchQuery) {
  return {
    type: UPDATE_PRODUCTS_SEARCH,
    payload: { searchQuery },
  };
}

async function updateProductImage({ id, image }) {
  api.products.updateProductImage(image, id, "formData");
}

function updateProductsFetching(isFetching) {
  return {
    type: UPDATE_PRODUCTS_FETCHING,
    payload: { isFetching },
  };
}

// product

export const getProductId = (id) => {
  return async (dispatch, state) => {

    await dispatch(updadeLoadPageClients(true));

    let { data } = await api.products.getProduct(id);

    await dispatch({
      type: GET_PRODUCT_BY_ID,
      payload: data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}

export const updateFormProduct = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PRODUCT,
      payload: { item: data.valueKey, data: data.value },
    });
  }
}

export const resetFormProduct = () => ({
  type: RESET_FORM_PRODUCT,
  payload: null,
});

export const updateFormProductTypePrice = (index, id, value) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PRODUCT_TYPE_PRICE,
      payload: { index, id, value },
    });
  }
}

export const createFormProductPricesEntry = (citySyncId) => {
  return {
    type: CREATE_FORM_PRODUCT_PRICES_ENTRY,
    payload: citySyncId,
  }
}

export const deleteFormProductPricesEntry = (index) => {
  return {
    type: DELETE_FORM_PRODUCT_PRICES_ENTRY,
    payload: index,
  }
}

export const updateFormProductComboSlots = (slotId, update) => ({
  type: UPDATE_FORM_PRODUCT_COMBO_SLOTS,
  payload: { slotId, update },
});

export const createProductTag = (tag) => {
  return async (dispatch, state) => {
   
    dispatch(updateItemLoadByName("isLoadProductsTags", true));

    const response = await api.tags.createTag({ title: tag });
    if (response.id && response.title) {
      const { 
        products: { 
          formProduct: { tags },
        } 
      } = state();

      dispatch(updateFormProduct({
        valueKey: "tags",
        value: [...tags, response],
      }));
    }

    dispatch(updateItemLoadByName("isLoadProductsTags", false));

  }
}
