import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import VisualEditor from "components/VisualEditor";
import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import InputImage from "components/ui-kit/InputImage";
import { LoadingModal } from "components/loadingOrder/loading";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import RestaurantSchedule from "components/RestaurantSchedule";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";
import classes from "./Restaurant.module.scss";

import { validateRestaurant } from "utils/validation";
import { generalDataItemsMenu } from 'constants/menuPageSettings';
import { RADIO_SCHEME } from 'constants/schemes';

import { Stack, Box } from "@mui/material";
import { cartNotifyMessageTemplates, followOrderMessageTemplates } from "./templates";

const Restaurant = ({
    //state
    data,
    isLoadPage,
    //dispatch
    updateFormRestaurant,
    uploadImage,
    getRestorant,
    cleaningNewForm,
    updateRestorant,
    createRestorant
}) => {
    let { code } = useParams();
    let history = useHistory();

    const [fileImg, setFileImg] = useState(undefined);
    // const [cartNotifyImg, setCartNotifyImg] = useState(undefined);

    const [error, setErrors] = useState({
        name: "",
        order_review_sms_text: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    const createRestaurantCallback = (code) => {
        history.push(`/settings/general${generalDataItemsMenu['restaurants'].path}/${code}`);
    }

    const handleFileChange = (objectFile) => {
        setFileImg(objectFile);
    };

    const handleCartNotifyImg = async (objectFile) => {
        if (!objectFile) return;

        const formData = new FormData();

        formData.append('type', 'cart_notify');
        formData.append('image', objectFile, objectFile.name);

        await uploadImage(formData, ({ file_path: filePath }) => {
            updateFormRestaurant("cart_notify_image", filePath);
        });
    };

    const handleSubmit = () => {
        validateRestaurant({
            data: {
                name: data.name,
                order_review_sms_text: data.order_review_sms_text,
                sale_employee_pickup: data.sale_employee_pickup,
                sale_employee_delivery: data.sale_employee_delivery,
            },

            onSuccess: async (validData) => {
                const formData = new FormData();

                if (fileImg) {
                    formData.append('image', fileImg, fileImg.name);
                }

                if (data?.id) {
                    updateRestorant(fileImg ? formData : undefined);
                } else {
                    createRestorant(createRestaurantCallback, fileImg ? formData : undefined);
                }

                setErrors({
                    name: "",
                    order_review_sms_text: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    useEffect(() => {
        if (code !== "new")
            getRestorant(code);

        if (code == "new")
            cleaningNewForm("editRestaurant");
    }, []);

    return (
        <div className={`${styles.d_max_1023}`}>
            <Back />

            <div className={`${styles.block}`}>
                <InputImage
                    currentImage={data?.logo_path}
                    setFileImage={handleFileChange}
                />
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.name}
                    isStyle={true}
                    error={error.name}
                    onChange={(e) => updateFormRestaurant("name", e.target.value)}
                />

                <TextInput
                    title="Телефон"
                    type="text"
                    value={data.contacts?.phone}
                    isStyle={true}
                    onChange={(e) => updateFormRestaurant("contacts.phone", e.target.value)}
                />

                <TextInput
                    title="Електронна адреса"
                    type="text"
                    value={data.contacts?.email}
                    isStyle={true}
                    onChange={(e) => updateFormRestaurant("contacts.email", e.target.value)}
                />

                <TextInput
                    title="Фізична адреса"
                    type="text"
                    value={data.contacts?.address}
                    isStyle={true}
                    onChange={(e) => updateFormRestaurant("contacts.address", e.target.value)}
                />

                <fieldset className={styles.fieldset}>
                    <legend>Поле тексту, розсилки повідомлень для опитування</legend>
                    <TextArea
                        isStyle={true}
                        height="150px"
                        error={error.order_review_sms_text}
                        onChange={(e) => updateFormRestaurant("order_review_sms_text", e.target.value)}
                        value={data.order_review_sms_text}
                    />
                    <div>Інструкція: Змінні для автозаповнення: %order_id% - ID замовленя; %restaurant_code% – код ресторану</div>
                </fieldset>

                <br />

                <Stack component="fieldset" className={styles.fieldset} spacing={1}>
                    <legend>Розсилка повідомлень забутого кошику</legend>
                    <Stack spacing={1} direction="row">
                        <InputImage
                            currentImage={data?.cart_notify_image}
                            manualChangeCallback={handleCartNotifyImg}
                            classNames={[classes.cartNotifyImage]}
                        />
                        <Stack spacing={1} sx={{ width: "100%" }}>
                            <TextInput
                                title="Текст кнопки"
                                type="text"
                                value={data.cart_notify_btn_name}
                                isStyle={true}
                                error={error.cart_notify_btn_name}
                                onChange={(e) => updateFormRestaurant("cart_notify_btn_name", e.target.value)}
                                width="100%"
                            />
                            <TextInput
                                title="Посилання кнопки"
                                type="text"
                                value={data.cart_notify_btn_link}
                                isStyle={true}
                                error={error.cart_notify_btn_link}
                                onChange={(e) => updateFormRestaurant("cart_notify_btn_link", e.target.value)}
                                width="100%"
                            />
                        </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                        Запланувати відправку через
                        <Box sx={{ px: 1 }}>
                            <TextInput
                                title="3"
                                type="number"
                                value={data.cart_notify_delay}
                                isStyle={true}
                                isTitle={false}
                                error={error.cart_notify_delay}
                                onChange={(e) => updateFormRestaurant("cart_notify_delay", e.target.value)}
                                width="50px"
                                height="auto"
                                stylePadTop="0"
                                styleMarg="0"
                            />
                        </Box>
                        хвилин
                    </Stack>

                    <VisualEditor
                        title="Редактор повідомлення"
                        value={data.cart_notify_message}
                        onChange={(text) => updateFormRestaurant("cart_notify_message", text)}
                        templates={cartNotifyMessageTemplates}
                    />

                </Stack>

                <br />

                <Stack component="fieldset" className={styles.fieldset} spacing={1}>
                    <legend>Повідомлення про відстеження замовлення</legend>
                    <VisualEditor
                        value={data.follow_order_message}
                        onChange={(text) => updateFormRestaurant("follow_order_message", text)}
                        templates={followOrderMessageTemplates}
                    />
                </Stack>

                <div className={styles.wr_drop}>
                    <fieldset className={styles.fieldset}>
                        <legend>
                            Знижка для співробітників у відсотках
                        </legend>
                        <div className={styles.row_2}>
                            <TextInput
                                title="Доставка"
                                type="number"
                                max={100}
                                min={0}
                                value={data.sale_employee_delivery}
                                isStyle={true}
                                error={error.sale_employee_delivery}
                                onChange={(e) => updateFormRestaurant("sale_employee_delivery", e.target.value)}
                            />
                            <TextInput
                                title="Самовивіз"
                                type="number"
                                max={100}
                                min={0}
                                value={data.sale_employee_pickup}
                                isStyle={true}
                                error={error.sale_employee_pickup}
                                onChange={(e) => updateFormRestaurant("sale_employee_pickup", e.target.value)}
                            />
                        </div>
                    </fieldset>
                </div>
                <div className={styles.wr_drop}>
                    <Radio
                        colors={"#000"}
                        items={RADIO_SCHEME}
                        label="Активність ресторану"
                        valueKey="status"
                        value={data.status}
                        onChange={(e) => updateFormRestaurant("status", e.value.value)}
                    />
                </div>
            </div>

            <RestaurantSchedule
                data={data}
                updateForm={updateFormRestaurant}
            />

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Restaurant.propTypes = {
    data: PropTypes.object,
    isLoadPage: PropTypes.bool,
    updateFormRestaurant: PropTypes.func,
    getRestorant: PropTypes.func,
    cleaningNewForm: PropTypes.func,
    updateRestorant: PropTypes.func,
    createRestorant: PropTypes.func,
};

export default Restaurant;