import { connect } from 'react-redux';
import styled from 'styled-components';

import * as productsActions from '../../store/actions/products';
import * as settingsActions from '../../store/actions/settings';
import { updateModalDelete } from 'store/actions/view';

import { productsWithSearchSelector } from '../../selectors/products';

// add icon
import Add from '@mui/icons-material/Add';

import { default as ProductsPage } from './ProductsPage';

function mapStateToProps(state) {
    const productCategories = state.settings.productCategories.filter(el => el.parent_id !== 0).map(el => ({
        key: el.sync_id,
        title: el.name,
        obj: el
    }));

    const restoransCategories = state.settings.restaurants.map(el => ({
        value: el.code,
        label: el.name
    }));

    return {
        products: productsWithSearchSelector(state),
        productsMeta: state.products.meta,
        isFetching: state.products.isFetching,
        productCategories: [
          { key: 'all', title: 'Всі' }, 
          ...productCategories, 
          { key: 'add-product-category', title: <Label><Add fontSize="inherit" /> Додати</Label> }
        ],
        restaurants: restoransCategories,
        cutlery: state.settings.cutlery,
        isOpenModalDeleteCategory: state.view.modalDelete.isOpen,
        isLoadModalDeleteCategory: state.view.modalDelete.loadingsModal,
    };
}

const Label = styled.span`
  text-align: center;
  font-weight: bold;
`;

const mapDispatchToProps = {
    updateModalDelete,
    ...productsActions,
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
