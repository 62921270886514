import { useState, useRef, useEffect } from 'react';

import SvgIcon from 'components/ui-kit/SvgIcon';
import styles from './inputImage.module.scss';
import classnames from 'classnames';

const InputImage = ({
    currentImage = null,
    manualChangeCallback = null,
    setFileImage = () => { },
    classNames = [],
}) => {
    const fileInputRef = useRef();
    const [image, setImage] = useState(currentImage);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const blob = new Blob([file], { type: file.type });
        const objectURL = URL.createObjectURL(blob);

        if (manualChangeCallback)
            return manualChangeCallback(file);

        setImage(objectURL);
    }

    const handleUploadFile = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        setFileImage(fileInputRef.current.files[0]);
    }, [image]);

    useEffect(() => {
        setImage(currentImage);
    }, [currentImage]);

    return (
        <div className={styles.image_wr}>
            <div
                className={classnames(styles.imageContainer, ...classNames)}
                onClick={handleUploadFile}
            >
                {image && (
                    <img
                        alt='product'
                        src={image}
                        className={styles.image}
                    />
                )}

                <div className={styles.addImage}>
                    <SvgIcon type='addImage' />
                </div>

                <input
                    ref={fileInputRef}
                    type='file'
                    accept='.png,.jpg,.jpeg'
                    onChange={handleFileChange}
                    hidden
                />
            </div>
        </div>
    );
};

export default InputImage;