import React from "react";
import Select from "components/ui-kit/Select";

// api
import api from "apiSingleton";

// styles
import styles from "./ProductPage.module.scss";

// redux
import { useSelector } from "react-redux";

// mappers
import { productTagsMappedSelect, productTagsMappedSelectForm } from "utils/mappers/products";

const isLoadingSelector = (state) => state.view.isLoadProductsTags;

const TagsPicker = ({
  value = [],
  updateFormProduct = () => {},
  createProductTag = () => {},
}) => {
  const isLoading = useSelector(isLoadingSelector);
  const loadOptions = React.useCallback(async (search) => {
    const response = await api.tags.searchTags(search);
    return response.map(({ id: value, title: label }) => ({ 
      label, 
      value 
    }));
  }, []);

  return (
    <Select
      className={styles.input}
      onChange={(props) => updateFormProduct({ 
        valueKey: props.valueKey, 
        value: productTagsMappedSelectForm(props.value),
      })}
      selectOptions={[]}
      selectedOption={productTagsMappedSelect(value)}
      valueKey="tags"
      mode="primary"
      label="Підкатегорії"
      isLoading={isLoading}
      isLoad={isLoading}
      loadOptions={loadOptions}
      onCreateOption={createProductTag}
      creatable
      async
      isMulti
    />
  );
}

export default TagsPicker;
