/*  camelcase */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isArray } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import NavigationTabs from '../../components/ui-kit/NavigationTabs/NavigationTabs';
import Loader from '../../components/ui-kit/Spiner';
import Product from './ProductСard';
import Pagination from "components/ui-kit/Pagination";
import styles from './ProductsPage.module.scss';
import BtnDisplayType from 'components/ui-kit/BtnDisplayType';
import Select from "components/ui-kit/Select";
import Table from "components/ui-kit/Table";
import ModalCategories from "components/modals/ModalCategories";
import ModalDelete from "components/modals/ModalDelete";
import ModalProductSpecification from 'components/modals/ModalProductSpecification';
import ButtonIcon from 'components/ui-kit/ButtonIcon';

import { InputSearch } from 'components/ui-kit/inputs/InputSearch';
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddIcon from '@mui/icons-material/Add';

import { getData, saveData } from 'utils/helpers/localStorage';

import { tableProducts } from "constants/tables";

const defOnj = {
  modal: {
    isOpen: false,
    isLoad: false,
  }
};

function ProductsPage({
  isFetching,
  products,
  productCategories,
  setFormCategories,
  updateFormProduct,
  resetFormProduct,
  restaurants,
  getProducts,
  getSettingsProductCategories,
  getProductSpecification,
  cutlery,
  updateProductCategory,
  createProductCategory,
  deleteProductCategory,
  isOpenModalDeleteCategory,
  isLoadModalDeleteCategory,
  updateModalDelete = () => { },
  productsMeta
}) {
  const [activeProductTabIndex, setActiveProductTabIndex] = useState(0);
  const [activeKitchenTabIndex, setActiveKitchenTabIndex] = useState(0);

  const activeProductTab = useMemo(() => {
    return productCategories[activeProductTabIndex]?.obj;
  }, [activeProductTabIndex, productCategories]);

  const [statModal, setStateModal] = useState({ ...defOnj.modal });
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [requestParams, setRequestParams] = useState({
    search: undefined,
    restaurant: restaurants[0]?.value || 'smaki',
    per_page: 30,
    isPage: true
  });
  const [typeReflection, setTypeReflection] = useState("table");
  const history = useHistory();
  const { path } = useRouteMatch();

  const performSearch = useCallback((event) => {
    if (!event || event.keyCode === 13) {
      getProducts(requestParams);
    }
  }, [getProducts, requestParams]);

  const updateSearchField = useCallback((event) => {
    const isClearButtonPressed = !event;
    const search = (!isClearButtonPressed && event.target.value) || undefined;

    setRequestParams((params) => ({
      ...params,
      search,
    }));

    if (isClearButtonPressed && !search) {
      getProducts({ ...requestParams, search });
    }
  }, [requestParams]);

  const renderSelect = (options, activeIndex, changeKey, label) => {
    return (
      <RestaurantPickerContainer className={styles.tabs}>
        <Select
          selectOptions={options}
          selectedOption={options[activeIndex]}
          label={label}
          onChange={({ value }) => {
            handleTabClick(changeKey)({
              index: options.indexOf(value),
              tab: { key: value.value, title: value.label },
            });
          }}
          valueKey={changeKey}
          mode='primary'
        />
      </RestaurantPickerContainer>
    );
  }

  const renderTabs = (tabs, activeTabIndex, changeKey, disabledTabs) => {
    return (
      <div className={styles.tabs}>
        <NavigationTabs
          tabs={tabs}
          tabMode='light'
          activeTabIndex={activeTabIndex}
          disabledTabs={disabledTabs}
          onTabClick={handleTabClick(changeKey)}
        />
      </div>
    );
  };

  const renderProducts = () => {
    return products.map(el => {
      const {
        image,
        title_ru,
        title_ua,
        weight,
        weight_type,
        description_ua,
        article,
        id
      } = el;

      return (
        <Product
          key={article}
          image={image}
          titleRu={title_ru}
          titleUa={title_ua}
          weight={weight}
          weightType={weight_type}
          descriptionUa={description_ua}
          id={id}
        />
      );
    });
  };

  const handleTabClick = (changeKey = null) => ({ tab, index = activeProductTabIndex }) => {

    if (tab.key === 'add-product-category') {
      return openModal();
    }

    history.push(`?page=1`);
    const newParams = { ...requestParams, [changeKey]: tab.key, page: 1 };

    switch (changeKey) {
      case 'restaurant': {
        setActiveKitchenTabIndex(index);
        setActiveProductTabIndex(0);

        delete newParams['category_sync_id'];
        break;
      }

      default: {
        setActiveProductTabIndex(index);
        break;
      }
    }

    if (newParams[changeKey] === 'all') {
      delete newParams[changeKey];
    }

    if (newParams[changeKey] !== requestParams[changeKey]) {
      setRequestParams(newParams);
      updateProducts(newParams);
    }
  };

  const updateProducts = (params) => {
    getProducts(params);
  };

  const onHandlerReflection = () => {
    setTypeReflection((prev) => {
      if (prev.includes("cards")) {
        saveData("typeReflection", { type: "table" });
        return "table";
      } else {
        saveData("typeReflection", { type: "cards" });
        return "cards";
      }
    });
  }

  const openModal = useCallback((isEdit = false) => {
    const payload = (isEdit && { 
      ...activeProductTab, 
      cutlery_sets: activeProductTab.cutlery_sets.map(item => item.id),
    }) || undefined;

    setFormCategories(payload);
    setStateModal((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, [setFormCategories, activeProductTab]);

  const closeModal = useCallback(() => {
    setStateModal(defOnj.modal);
    setIsLoadBtn(false);
  }, []);

  const handleCreateClick = useCallback(() => {
    const params = { restaurant: requestParams.restaurant };
    if (activeProductTab?.id) {
      updateProductCategory(setIsLoadBtn, closeModal, params);
    } else {
      createProductCategory(setIsLoadBtn, closeModal, params);
    }
  }, [activeProductTab?.id, requestParams.restaurant, createProductCategory, updateProductCategory]);

  const handleDeleteClick = useCallback(() => {
    const id = activeProductTab?.id;
    if (id) {
      setActiveProductTabIndex(0);
      deleteProductCategory(id, {
        restaurant: requestParams.restaurant,
      });
    }
  }, [activeProductTab?.id, requestParams.restaurant, deleteProductCategory]);

  const setPage = (page = 1) => {
    updateProducts({ ...requestParams, page });
    history.push(`?page=${page}`);
  }

  useEffect(() => {
    getProducts(requestParams)
  }, []);

  useEffect(() => {
    let type = getData("typeReflection")?.type;

    if (type)
      setTypeReflection(type);

    getSettingsProductCategories({ restaurant: requestParams.restaurant });
  }, [getSettingsProductCategories, requestParams.restaurant]);


  const handlerCreate = useCallback(() => {
    history.push(`${path}/new`);
    resetFormProduct();
    updateFormProduct({ valueKey: "restaurant", value: requestParams.restaurant });
  }, [resetFormProduct, updateFormProduct, requestParams.restaurant, history, path]);

  return (
    <>
      <div className={styles.products}>
        <div className={styles.header_top}>
          <div className={styles.header}>
            {renderTabs(productCategories, activeProductTabIndex, 'category_sync_id')}
            {renderSelect(restaurants, activeKitchenTabIndex, 'restaurant', 'Виберіть ресторан')}
          </div>
          <div className={styles.row_jus}>
            <div className={styles.row_jus}>
              <InputSearch
                placeholder="Пошук товару"
                value={requestParams.search}
                onChange={updateSearchField}
                onKeyDown={performSearch}
              />
              {(requestParams.search?.length > 0) && (
                <Ml>
                  <ButtonIcon
                    icon={<CleaningServicesIcon />}
                    colorBg="red"
                    onClick={updateSearchField}
                  />
                </Ml>
              )
              }
              <Ml>
                <ButtonIcon
                  icon={<SearchIcon />}
                  disabled={!requestParams.search?.length}
                  onClick={performSearch}
                />
              </Ml>
            </div>
            <div className={styles.flex_cent}>
              {
                (activeProductTabIndex > 0) && (
                  <>
                    <div>{(activeProductTab?.take_part_promotions_discounts == 1) ? "Без ігнорування акцій" : "Ігнорує акції"} </div>
                    <ButtonIcon disabled={isFetching} title='Редагувати' onClick={openModal.bind(null, true)} />
                    <ButtonIcon title='Видалити' colorBg='red' onClick={updateModalDelete.bind(null, "isOpen", true)} />
                  </>
                )
              }
            </div>
            <Gap>
              <ButtonIcon
                onClick={handlerCreate}
                title="Додати товар"
                icon={<AddIcon />}
                positionIcon="left"
              />
              <BtnDisplayType
                isType={typeReflection}
                onHandler={onHandlerReflection}
              />
            </Gap>
          </div>
        </div>

        {
          isFetching ? (
            <div className={styles.loader}>
              <Loader color='orange' width={40} height={40} />
            </div>
          ) : (
            <div className={styles.w_100}>
              {
                typeReflection.includes('table') ? (
                  <Table
                    data={products}
                    scheme={tableProducts}
                    actions={{ getProductSpecification }}
                    isLink={true}
                    link={`/products/`}
                  />
                ) : (
                  <div className={styles.body}>
                    {
                      renderProducts()
                    }
                  </div>
                )
              }
            </div>
          )
        }

        {
          isArray(products) && (
            products.length > 0 && (
              <Pagination
                onClick={setPage}
                currentPage={productsMeta.current_page}
                lastPage={productsMeta.last_page}
              />
            )
          )
        }
      </div>

      <ModalCategories
        isOpen={statModal.isOpen}
        isLoad={isLoadBtn}
        isLoadBtn={isLoadBtn}
        cutlery={cutlery}
        onCloseModal={closeModal}
        handleCreateClick={handleCreateClick}
      />

      <ModalDelete
        title="Видалити категорію ?"
        text="Ви підтверджуєте видалення ?"
        isOpen={isOpenModalDeleteCategory}
        isLoad={isLoadModalDeleteCategory}
        funDelete={handleDeleteClick}
        updateModalDelete={updateModalDelete}
      />

      <ModalProductSpecification />

    </>
  );
}

const Ml = styled.div`
    margin-left: 10px;
`;

const Gap = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const RestaurantPickerContainer = styled.div`
    width: 250px;
`;

ProductsPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  getProducts: PropTypes.func.isRequired,
  productCategories: PropTypes.array.isRequired
};

export default React.memo(ProductsPage);
