import PropTypes from "prop-types";
import { useEffect, useState, useCallback } from "react";
import { isArray } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { BannerEdit } from "./BannerEdit";
import ModalDelete from "components/modals/ModalDelete";

import AddIcon from "@mui/icons-material/Add";
import styles from "../scss/settings.module.scss";

import { tableBanners } from "constants/tables";

const Banners = ({
  // state
  isOpen,
  view,
  list,
  formEditBanners,
  selectedRestaurantId,
  // dispatch
  toggleFormBanners,
  updateFormBanners,
  getBannersList,
  getBannerId,
  createBanner,
  updateBannerId,
  deleteBannerById,
  uploadImage,
}) => {
  const [deleteItem, setDeleteItem] = useState({
    isOpen: false,
    id: undefined,
    isLoad: false,
  });

  useEffect(() => {
    toggleFormBanners(false);
    getBannersList();
  }, [selectedRestaurantId, toggleFormBanners, getBannersList]);

  const handlerEdit = useCallback(({ id }) => {
    getBannerId(id);
  }, [getBannerId]);

  const handlerCreate = useCallback(() => toggleFormBanners(true), [toggleFormBanners]);
  const handleSubmitClose = useCallback(() => toggleFormBanners(false), [toggleFormBanners]);
  const handleDelete = useCallback(({ id }) => {
    setDeleteItem(prev => ({
      ...prev,
      isOpen: true,
      id,
    }));
  }, []);

  const closeModalDelete = useCallback(() => {
    setDeleteItem({
      isOpen: false,
      id: undefined,
      isLoad: false,
    });
  }, []);

  const onDeleteItem = useCallback(() => {
    deleteBannerById(deleteItem.id);
  }, [deleteItem.id, deleteBannerById]);

  return (
    <div className={styles.d_max_1023}>
      <div className={styles.wr_head}>
        <ButtonIcon
          onClick={handlerCreate}
          title="Додати банер"
          icon={<AddIcon />}
          positionIcon="left"
          disabled={isOpen || view.isLoadEditBanners}
        />
      </div>
      <div className={styles.wr_row}>
        {
          !view?.isLoadPage ? (
            (isArray(list) && (list.length != 0)) ? (
              <Table
                onClick={handlerEdit}
                onDelete={handleDelete}
                data={list}
                scheme={tableBanners}
              />
            ) : (
              <div>Немає банерів...</div>
            )
          ) : (
            <LoadingCircl />
          )
        }
      </div>

      {isOpen && (
        <BannerEdit
          data={formEditBanners}
          view={view}
          onSubmit={createBanner}
          handleSubmitClose={handleSubmitClose}
          onSubmitUpdate={updateBannerId}
          updateFormBanners={updateFormBanners}
          uploadImage={uploadImage}
        />
      )}

      <ModalDelete
        title={'Видалити ? '}
        text={`Ви підтверджуєте видалення ?`}
        isOpen={deleteItem.isOpen}
        isLoad={deleteItem.isLoad}
        funDelete={onDeleteItem}
        onCloseModal={closeModalDelete}
      />
    </div>
  )
}

Banners.propTypes = {
  view: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
}

export default Banners;
