import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';

import history from 'constants/history';
import classnames from 'classnames';
import { validateProduct } from 'utils/validation';
import Button from 'components/ui-kit/Button';
import ButtonIcon from 'components/ui-kit/ButtonIcon';
import Input from 'components/ui-kit/Input';
import Radio from 'components/ui-kit/Radio';
import Checkbox from 'components/ui-kit/Checkbox';
import Select from 'components/ui-kit/Select';
import { Dropdown } from 'components/ui-kit/inputs/Dropdown';
import Back from "components/ui-kit/Back";
import InputImage from "components/ui-kit/InputImage";
import { LoadingModal } from "components/loadingOrder/loading";
import styles from './ProductPage.module.scss';
import { RADIO_SCHEME } from "constants/schemes";

import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';

// mappers
import { citiesMapped } from 'utils/mappers/cities';

import { Stack, Divider } from '@mui/material';
import TagsPicker from './TagsPicker';

import {
  categoriesMappedSuncId,
  categoriesMappedId,
  searchCategoriesSyncIdMulti,
  mapSelectedCategoriesToIdObjects,
  searchCategoriesSuncId
} from 'utils/mappers/categories';
import {
  restaurantsMappedLabel,
  searchRestaurantByCode
} from 'utils/mappers/restaurants';

function ProductPage({
  // states
  view,
  productTypes,
  restaurants,
  cities,
  createProduct,
  updateProduct,
  formEditProduct,
  typesOfDeliveryList,
  // dispatch
  getProductId,
  createProductTag,
  updateFormProduct,
  updateFormProductTypePrice,
  createFormProductPricesEntry,
  deleteFormProductPricesEntry,
  updateFormProductComboSlots,
  setIsLoadCategories,
  getSettingsProductCategories,
}) {
  const [fileImg, setFileImg] = useState(undefined);
  const [errors, setErrors] = useState({
    title_ua: '',
    description_ua: '',
    weight: '',
    weight_type: '',
    article: '',
  });

  const [cityForPrices, setCityForPrices] = useState(cities[0]?.sync_id);

  const mappedProductTypes = useMemo(() => categoriesMappedId(productTypes), [productTypes]);
  const productId = useMemo(() => history.location.pathname.split('/')[2], [history.location.pathname]);

  useEffect(() => {
    if (productId && productId !== "new") {
      getProductId(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (formEditProduct.restaurant && formEditProduct.restaurant !== "-") {
      updateFormProduct({ valueKey: "category", value: {} });
      getSettingsProductCategories({ restaurant: formEditProduct.restaurant }, setIsLoadCategories);
    }
  }, [getSettingsProductCategories, updateFormProduct, setIsLoadCategories, formEditProduct.restaurant]);

  const handleFileChange = (objectFile) => {
    setFileImg(objectFile);
  };

  const backs = () => {
    history.goBack();
  }

  const handleSave = () => {
    const formData = new FormData();
    const product = {
      ...formEditProduct
    };

    if (fileImg) {
      formData.append('image', fileImg, fileImg.name);
    }

    console.log("product: ", product);

    validateProduct({
      data: product,
      onSuccess: () => {
        const action = product.id ? updateProduct : createProduct;
        action(product, (fileImg ? formData : undefined), backs);
      },
      onError: validationErrors => setErrors(validationErrors)
    });
  };

  const renderPrices = () => {
    // TODO: fix error with null warning
    return (
      <div>
        <div className={classnames(styles.pricesItem, styles.flexEndAlign)}>
          <div className={styles.city}>Додати ціну за містом</div>
          <Dropdown
            onChange={(event) => setCityForPrices(event.target.value)}
            value={cityForPrices}
            list={citiesMapped(cities)}
            isLight
          />
          <ButtonIcon
            title="Додати"
            type="button"
            icon={<Add />}
            positionIcon="left"
            onClick={createFormProductPricesEntry.bind(null, cityForPrices)}
          />
        </div>

        <br />

        {formEditProduct.prices?.map((item, index) => {
          const city = cities.find(el => el.sync_id == item.city_sync_id);

          const { uuid = 0, name = "", status = 0 } = city;

          if (!status)
            return (<></>);

          const { price, price_old, is_active, delivery_type_prices } = item;

          return (
            <div
              key={uuid}
              className={styles.pricesItem}
            >
              <p className={styles.city}>{name}</p>

              <div className={styles.pricesInputs}>
                <Input
                  className={styles.input}
                  label='Ціни'
                  subLabel='(UAH)'
                  placeholder='Ціна'
                  value={`${price}`}
                  valueKey={`prices.[${index}].price`}
                  type='number'
                  onChange={props => updateFormProduct(props)}
                />

                <Input
                  className={styles.input}
                  label='Ціна без знижки'
                  subLabel='(UAH)'
                  placeholder='Ціна без знижки'
                  value={`${price_old}`}
                  valueKey={`prices.[${index}].price_old`}
                  type='number'
                  onChange={props => updateFormProduct(props)}
                />

                {
                  isArray(typesOfDeliveryList) && typesOfDeliveryList.map((item, indexPr) => {
                    if (item.code == "default") {
                      return;
                    }

                    let price = null;

                    if (isArray(delivery_type_prices) && delivery_type_prices.length > 0) {
                      delivery_type_prices.map((el) => {
                        if (item.id === el.delivery_type_id) {
                          price = el.price;
                        }
                      });
                    }

                    return (
                      <Input
                        key={indexPr}
                        className={styles.input}
                        label={`Ціна ${item.name}`}
                        subLabel='(UAH)'
                        placeholder='Ціна'
                        value={price}
                        valueKey='delivery_type_id'
                        type='number'
                        onChange={props => updateFormProductTypePrice(index, item.id, props.value)}
                      />
                    )
                  })
                }
              </div>

              <div className={styles.pricesRadioButtons}>
                {index === 0 && (
                  <p className={styles.radioTitle}>
                    Активність
                    <span> (Видимість товару на сайті)</span>
                  </p>
                )}

                <div
                  className={
                    `${styles.radioButtonsItem} ${index === 0 && styles.firstRadio}`
                  }
                >
                  <Radio
                    items={RADIO_SCHEME}
                    valueKey={`prices.[${index}].is_active`}
                    value={`${is_active}`}
                    onChange={({ valueKey, value }) => updateFormProduct({ valueKey, value: value.value })}
                  />
                </div>
                <ButtonIcon
                  icon={<Delete />}
                  type='button'
                  colorBg='red'
                  title='Видалити'
                  positionIcon='left'
                  onClick={deleteFormProductPricesEntry.bind(null, index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderComboBoxSettings = () => {
    return isArray(formEditProduct.combo_boxes) && (
      <fieldset className={classnames(styles.fieldset, styles.comboBoxSettings)}>
        <legend className={styles.radioTitle}>Налаштування комбо-боксу</legend>
        <Stack className={styles.comboBoxes} divider={<Divider />} spacing={1}>
          {formEditProduct.combo_boxes.map((box) => (
            <div key={box.id} className={styles.comboBox}>
              <Select
                className={styles.input}
                valueKey='categories'
                mode='primary'
                label={`Слот ${box.id}: категорії продуктів`}
                selectOptions={mappedProductTypes}
                selectedOption={searchCategoriesSyncIdMulti(box.product_categories, productTypes)}
                onChange={({ value }) => {
                  const selection = mapSelectedCategoriesToIdObjects(value);
                  updateFormProductComboSlots(box.id, { product_categories: selection });
                }}
                isSearchable
                isMulti
                noMargin
              />
              <Stack>
                <Checkbox
                  title="Обов'язковий"
                  onChecked={() => updateFormProductComboSlots(box.id, { required: !box.required })}
                  checked={box.required}
                  value={box.required}
                  isStyle
                />
                <div style={{ filter: "grayscale(1)" }}>
                  <Checkbox
                    title="Активний"
                    onChecked={() => { }}
                    checked={true}
                    value={true}
                    isStyle
                  />
                </div>
              </Stack>
              <ButtonIcon
                icon={<Delete />}
                type='button'
                colorBg='red'
                onClick={() => updateFormProductComboSlots(box.id)}
              />
            </div>
          ))}
          <ButtonIcon
            icon={<Add />}
            positionIcon='left'
            title='Додати слот'
            type='button'
            onClick={updateFormProductComboSlots}
          />
        </Stack>
      </fieldset>
    );
  }

  return (
    <div className={styles.container}>
      <Back />

      <div className={styles.header}>
        <InputImage
          currentImage={formEditProduct?.image || ''}
          setFileImage={handleFileChange}
        />

        <div className={styles.wrBtns}>
          <Button
            className={styles.buttons}
            label='Зберегти'
            onClick={handleSave}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div>
          <Input
            className={styles.input}
            label='Назва'
            placeholder='Назва товару'
            value={formEditProduct?.title_ua || ""}
            valueKey='title_ua'
            error={errors.title_ua}
            onChange={updateFormProduct}
          />
          <Select
            className={styles.input}
            onChange={(props) => updateFormProduct({ valueKey: props.valueKey, value: { name: props.value.label, sync_id: props.value.value } })}
            selectOptions={categoriesMappedSuncId(productTypes)}
            selectedOption={searchCategoriesSuncId((formEditProduct.category?.sync_id || null), productTypes)}
            valueKey='category'
            mode='primary'
            label='Категорія'
            isLoad={view.isLoadProductsCategories}
            isLoading={view.isLoadProductsCategories}
          />

          <TagsPicker
            value={formEditProduct.tags}
            updateFormProduct={updateFormProduct}
            createProductTag={createProductTag}
          />

          <Input
            className={styles.input}
            label='Опис товару'
            placeholder='Опис товару'
            value={formEditProduct.description_ua || ''}
            valueKey='description_ua'
            error={errors.description_ua}
            onChange={updateFormProduct}
          />
          <Input
            className={styles.input}
            label='Артикул'
            value={formEditProduct.article}
            valueKey='article'
            error={errors.article}
            onChange={updateFormProduct}
          // subLabel='(Нередаговане поле)'
          // readOnly
          />
          <Input
            className={styles.input}
            label='Вага'
            type='number'
            subLabel={!!formEditProduct.weight_type && `(${formEditProduct.weight_type}.)`}
            value={formEditProduct.weight || ''}
            valueKey='weight'
            error={errors.weight}
            onChange={updateFormProduct}
          // readOnly
          />
          <Input
            className={styles.input}
            label='Тип ваги'
            subLabel='(наприклад, г./мл./л.) - без крапки'
            value={formEditProduct.weight_type}
            valueKey='weight_type'
            error={errors.weight_type}
            onChange={updateFormProduct}
          // readOnly
          />
          <Select
            className={styles.input}
            onChange={(props) => updateFormProduct({ valueKey: props.valueKey, value: props.value.value })}
            selectOptions={restaurantsMappedLabel(restaurants)}
            selectedOption={searchRestaurantByCode(formEditProduct.restaurant, restaurants)}
            valueKey='restaurant'
            mode='primary'
            label='Ресторан'
          />
          {renderComboBoxSettings()}
        </div>
        <div>
          {renderPrices()}
        </div>
      </div>
      {
        view.isLoadPage && (
          <LoadingModal isFixed={true} />
        )
      }
    </div>
  );
}

ProductPage.propTypes = {
  editedProduct: PropTypes.object,
  productTypes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  updateProduct: PropTypes.func.isRequired
};

ProductPage.defaultProps = {
  editedProduct: {}
};

export default ProductPage;
