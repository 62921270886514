import moment from "moment";
import classNames from "classnames";
import { useCallback } from "react";

// icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// components
import Checkbox from "components/ui-kit/Checkbox";
import { TimeInput } from "components/ui-kit/inputs/TimeInput";
import { Tooltip, IconButton } from "@mui/material";

// constants
import { DAYS as days } from "constants/schemes";

// styles
import styles from "pages/Settings/scss/settings.module.scss";

const RestaurantSchedule = ({
  data = {},
  updateForm = () => { },
}) => {

  const onScheduleTimeChange = useCallback((time, valueKey) => {
    updateForm(valueKey, time.format("HH:mm"));
  }, [updateForm]);

  return (
    <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`} style={{ position: "relative" }}>
      <div className={`${styles.title_in} wr-block-title`}>Час роботи</div>
      <div className={classNames("wr-block-content", styles.row_2, styles.colgap_25)}>
        {days.map(({ title }, index) => {
          const value = (index + 1).toString();
          const day = data.schedule?.[value] ?? {};
          return (
            <fieldset className={classNames(styles.row_form, styles.fieldset, styles.row_numb_field)} key={value}>
              <div className={`${styles.wr_drop} ${styles.item_two}`}>
                <Checkbox
                  title={title}
                  isStyle
                  onChecked={(prevChecked) => updateForm(`schedule.${value}.work`, !prevChecked)}
                  checked={day.work}
                  value={day.work}
                />
              </div>
              <div className={`${styles.wr_drop} ${styles.item_two}`}>
                <TimeInput
                  width="100%"
                  title="Відкриття"
                  value={(day.time_open || null) && moment(day.time_open, "HH:mm")}
                  valueKey={`schedule.${value}.time_open`}
                  onChange={onScheduleTimeChange}
                  isStyle
                />
              </div>
              <div className={`${styles.wr_drop} ${styles.item_two}`}>
                <TimeInput
                  width="100%"
                  title="Закриття"
                  value={(day.time_close || null) && moment(day.time_close, "HH:mm")}
                  valueKey={`schedule.${value}.time_close`}
                  onChange={onScheduleTimeChange}
                  isStyle
                />
              </div>
            </fieldset>
          )
        })}
      </div>
      <Tooltip
        title="Якщо увімкнено чекбокс, то день є робочим"
        placement="right-start"
        sx={{
          position: "absolute",
          margin: "-16px",
          top: 0,
          right: 0,
        }}>
        <IconButton size="small">
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default RestaurantSchedule;
