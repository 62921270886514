import { useMemo } from "react";

import Select from "components/ui-kit/Select";

// styled
import styled from "styled-components";

// mappers
import { restaurantsMappedLabelId } from "utils/mappers/restaurants";

const SitesRestaurantPicker = ({
  // state
  view,
  restaurants,
  selectedRestaurant,
  // actions
  selectSiteSettingsRestaurant = () => { },
}) => {
  const options = useMemo(() => restaurantsMappedLabelId(restaurants), [restaurants]);
  const disabled = useMemo(() => {
    return view.isLoadEditBanners;
  }, [view]);

  return (
    <Container>
      <Select
        selectOptions={options}
        selectedOption={selectedRestaurant}
        valueKey="restaurant"
        mode="primary"
        onChange={selectSiteSettingsRestaurant}
        disabled={disabled}
        noMargin
      />
    </Container>
  );
}

const Container = styled.div`
  width: 150px;
`;

export default SitesRestaurantPicker;
