import { useEffect, useState, useCallback, useMemo } from "react";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Table from "components/ui-kit/Table";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import ModalCategories from "components/modals/ModalCategories";
import ModalDelete from "components/modals/ModalDelete";

import { tableCategories } from "constants/tables";
import AddIcon from "@mui/icons-material/Add";

import styles from "./../scss/settings.module.scss";

const defOnj = {
  modal: {
    id: null,
    isOpen: false,
    isLoad: false,
  }
};

const Categories = ({
  // state
  categoriesParams = {},
  categoriesForBrands = false,
  productCategories,
  productCategoriesForm,
  cutlery,
  isOpenModalDelete,
  isLoadModalDelete,
  // dispatrch
  updateModalDelete,
  getSettingsProductCategories,
  oneSettingsProductCategories,
  updateProductCategory,
  createProductCategory,
  deleteProductCategory,
  cleaningNewForm,
}) => {
  const [isLoadPagem, setIsLoadPage] = useState(false);
  const [statModal, setStateModal] = useState({ ...defOnj.modal });
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const handleDelete = useCallback(({ id }) => {
    setDeleteItem(id);
    updateModalDelete("isOpen", true);
  }, [updateModalDelete]);

  const onDeleteItem = useCallback(() => {
    if (!deleteItem) return;
    const params = categoriesForBrands ? {
      categoriesForBrands,
      restaurant: categoriesParams.restaurant,
      parent_id: 0,
    } : {};

    deleteProductCategory(deleteItem, params);
  }, [categoriesForBrands, categoriesParams?.restaurant, deleteItem]);

  const loadModal = useCallback((status) => {
    setStateModal((prev) => ({
      ...prev,
      isLoad: status,
    }));
  }, []);

  const openModal = useCallback(({ id }) => {
    if (id) {
      oneSettingsProductCategories(id, loadModal);
    } else {
      cleaningNewForm("productCategoriesForm");
    }

    setStateModal((prev) => ({
      ...prev,
      id,
      isOpen: true,
    }));
  }, [oneSettingsProductCategories, cleaningNewForm]);

  const closeModal = useCallback(() => {
    setStateModal(defOnj.modal);
    setIsLoadBtn(false);
  }, []);

  const handleCreateClick = useCallback(() => {
    const params = categoriesForBrands ? {
      categoriesForBrands,
      restaurant: categoriesParams.restaurant,
      parent_id: 0,
    } : {};

    if (productCategoriesForm?.id) {
      updateProductCategory(setIsLoadBtn, closeModal, params);
    } else {
      createProductCategory(setIsLoadBtn, closeModal, params);
    }
  }, [categoriesForBrands, productCategoriesForm?.id, categoriesParams?.restaurant, updateProductCategory, createProductCategory]);

  useEffect(() => {
    getSettingsProductCategories(categoriesParams, setIsLoadPage);
  }, [JSON.stringify(categoriesParams)]);

  const tableData = useMemo(() => {

    if (!categoriesForBrands) {
      return productCategories.filter(el => el.parent_id !== 0);
    }

    return productCategories;

  }, [categoriesForBrands, productCategories]);

  return (
    <div>
      <div className={styles.wr_head}>
        <ButtonIcon
          onClick={() => openModal({ id: null })}
          title="Додати категорію"
          icon={<AddIcon />}
          positionIcon="left"
        />
      </div>
      <div className={styles.block}>
        {
          !isLoadPagem && (
            <>
              <div className={styles.block}>
                <Table
                  onClick={openModal}
                  onDelete={handleDelete}
                  data={tableData}
                  scheme={tableCategories}
                />
              </div>
            </>
          )
        }

        {
          isLoadPagem && (
            <LoadingCircl />
          )
        }
      </div>

      <ModalCategories
        isOpen={statModal.isOpen}
        isLoad={statModal.isLoad}
        isLoadBtn={isLoadBtn}
        cutlery={cutlery}
        onCloseModal={closeModal}
        handleCreateClick={handleCreateClick}
        categoriesForBrands={categoriesForBrands}
      />

      <ModalDelete
        title="Видалити ? "
        text="Ви підтверджуєте видалення ?"
        isOpen={isOpenModalDelete}
        isLoad={isLoadModalDelete}
        funDelete={onDeleteItem}
        updateModalDelete={updateModalDelete}
      />

    </div>
  )
}

export default Categories;