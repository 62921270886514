export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FETCHING = 'UPDATE_PRODUCTS_FETCHING';
export const UPDATE_PRODUCTS_SEARCH = 'UPDATE_PRODUCTS_SEARCH';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const RESET_FORM_PRODUCT = 'RESET_FORM_PRODUCT';
export const UPDATE_FORM_PRODUCT = 'UPDATE_FORM_PRODUCT';
export const UPDATE_FORM_PRODUCT_TYPE_PRICE = 'UPDATE_FORM_PRODUCT_TYPE_PRICE';
export const CREATE_FORM_PRODUCT_PRICES_ENTRY = 'CREATE_FORM_PRODUCT_PRICES_ENTRY';
export const DELETE_FORM_PRODUCT_PRICES_ENTRY = 'DELETE_FORM_PRODUCT_PRICES_ENTRY';
export const UPDATE_FORM_PRODUCT_COMBO_SLOTS = 'UPDATE_FORM_PRODUCT_COMBO_SLOTS';
export const UPDATE_MODAL_PRODUCT_SPECIFICATION = 'UPDATE_MODAL_PRODUCT_SPECIFICATION';
