import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import Select from "components/ui-kit/Select";
import Checkbox from "components/ui-kit/Checkbox";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { BLACK_LIST, MAIN_CATEGORY_TYPE_LIST } from 'constants/schemes';
import Radio from "components/ui-kit/Radio";

import {
  cutleryMappedLabel,
  filterCutleryId,
  searchCutleryToid
} from "utils/mappers/cutlery";

import {
  categoriesMappedSelect,
  searchCategoryId,
} from "utils/mappers/categories";

import {
  restaurantsMappedLabel,
  searchRestaurantByCode
} from "utils/mappers/restaurants";

const ModalCategories = ({
  // props
  isOpen = false,
  isLoad = false,
  isLoadBtn = false,
  categoriesForBrands = false,
  data = {},
  restaurants = [],
  categories = [],
  cutlery,
  onCloseModal = () => { },
  handleCreateClick = () => { },
  updateCategoriesForm = () => { },
}) => {
  const theme = useTheme();

  // if (!data?.id)
  //     return <></>;

  return (
    <ModalLayout
      theme={theme}
      modalIsOpen={isOpen}
      closeModal={onCloseModal}
    >
      {
        !isLoad && (
          <>
            <Title>{data.name}</Title>
            <TextInput
              title="Назва"
              value={data.name}
              isStyle={true}
              onChange={(e) => updateCategoriesForm("name", e.target.value)}
            />
            {!categoriesForBrands && (
              <>
                {!data.id && (
                  <Select
                    onChange={(props) => updateCategoriesForm("restaurant", props.value.value)}
                    selectOptions={restaurantsMappedLabel(restaurants)}
                    selectedOption={searchRestaurantByCode(data.restaurant, restaurants)}
                    valueKey='restaurant'
                    mode='primary'
                    label='Ресторан'
                  />
                )}
                <Select
                  selectOptions={cutleryMappedLabel(cutlery.list)}
                  selectedOption={data.cutlery_sets ? searchCutleryToid(data.cutlery_sets, cutlery.list) : []}
                  valueKey='cutlery_sets'
                  label='Столові прибори'
                  isMulti
                  mode={'primary'}
                  onChange={({ value }) => updateCategoriesForm("cutlery_sets", filterCutleryId(value))}
                />
                <Select
                  selectOptions={categoriesMappedSelect(categories)}
                  selectedOption={searchCategoryId(data.parent_id, categories)}
                  valueKey='parent_id'
                  label='Головна категорія'
                  mode={'primary'}
                  onChange={(props) => updateCategoriesForm("parent_id", props.value.value)}
                />
                <TextInput
                  title="Поле для сортування вказати від 1"
                  value={data.sort}
                  isStyle={true}
                  onChange={(e) => updateCategoriesForm("sort", e.target.value)}
                />
                <br />
                <Radio
                  colors={"#000"}
                  items={BLACK_LIST}
                  label="Є списання продуктів ? (при скасуванні замовлення)"
                  valueKey="write_off_products"
                  value={data.write_off_products}
                  onChange={(e) => updateCategoriesForm("write_off_products", e.value.value)}
                />
                <br />
                <Radio
                  colors={"#000"}
                  items={BLACK_LIST}
                  label="Бере участь в акціях ?"
                  valueKey="take_part_promotions_discounts"
                  value={data.take_part_promotions_discounts}
                  onChange={(e) => updateCategoriesForm("take_part_promotions_discounts", e.value.value)}
                />
                <br />
                <Radio
                  colors={"#000"}
                  items={MAIN_CATEGORY_TYPE_LIST}
                  label="Головна категорія"
                  valueKey="main_category"
                  value={data.main_category}
                  onChange={(e) => updateCategoriesForm("main_category", e.value.value)}
                />
                <br />
                <Checkbox
                  title="Приховати категорію на сайті"
                  value={data.hidden}
                  checked={data.hidden}
                  onChecked={(value) => updateCategoriesForm("hidden", !value)}
                  isStyle
                  isMin
                />
                <br />
              </>
            )}
            <Wrcenter>
              <ButtonIcon
                title={data.id ? "Оновити" : "Зберегти"}
                onClick={handleCreateClick}
                isLoad={isLoadBtn}
              />
            </Wrcenter>
          </>
        )
      }

      {
        isLoad && (
          <LoadingCircl mins={true} />
        )
      }
    </ModalLayout>
  )
}

const Title = styled.div`
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Wrcenter = styled.div`
    display: flex;
    justify-content: center;
`;

export default ModalCategories;