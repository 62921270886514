import produce from "immer";
import { isArray, isEqual, cloneDeep, set as lodashSet, get as lodashGet } from "lodash";

import {
  UPDATE_ORDER,
  GET_UPDATE_ORDER,
  UPDATE_ORDER_DATA,
  UPDATE_ORDER_CLIENT,
  UPDATE_ORDER_CLIENT_GET,
  RESET_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ITEMS,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  SELECT_PIZZA_HALF,
  SELECT_SWAPPABLE_PRODUCT,
  INCREASE_ORDER_ITEM_QUANTITY,
  DECREASE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM_COMMENT,
  UPDATE_ORDER_PAYMENTS,
  ADD_ORDER_PAYMENTS,
  REMOVE_ORDER_PAYMENTS,
  RESET_ORDER,
  GET_KITCHEN_ADDRESS,
  GET_ITEM_ORDER_BY_ID,
  UPDATE_ITEM_DOP_ADD,
  UPDATE_DELETE_DOP,
  UPDATE_ITEM_DELETE,
  GET_LIST_STREET,
  CLEANING_LIST_STREET,
  ADD_ONE_DOP,
  SUBTRACT_ONE_DOP,
  UPDATE_LOAD_CLIENT,
  LOAD_CLIENT,
  ORDER_UPDATE_FORM_REASON,
  ORDER_UPDATE_FORM_REASON_STATUS,
  SET_ORDER_ITEMS,
  GET_ORDER_PRINT,
  CLEAТ_FORM_CLIENT,
  GET_ORDERS_ALL_CLIETN_ID,
  CLEAN_FORM_REASON,
  SET_DISCOUNTS,
  REMOVE_PROMO_GIFT_DISCOUNT,
  REMOVE_ORDER_PROMOCODE,
  CLEAR_DATA_CLIENT_ORDER,
  ADD_CUTLERY_ITEM,
  UPDATE_CUTLERY_ITEM,
  REMOVE_CUTLERY_ALL,
  REMOVE_CUTLERY_ITEM,
  UPDATE_ORDER_PAYMENTS_ITEM,
  REASON_GET_INFORMATION,
  DELETE_BELONS_PRODUCT,
  UPDATE_ORDER_ITEM_QUANTITY,
  CLEAN_FORM_ORDER,
  SET_ORDER_DELIVERY_TIME,
  SET_ORDER_DELIVERY_TIME_LOADING,
  SELECT_COMBO_BOX,
  TOGGLE_ADDITIONS_MENU,
  SET_ADDITIONS_MENU_LOADING,
  SET_ADDITIONS_MENU_GROUPS,
} from "../constants/order";

import { OPEN_EDITORCALLCENTER, CLOSE_EDITORCALLCENTER } from "store/constants/view";

import {
  GET_SOURCE_CLIENT
} from '../constants/clients';

const initialState = {
  data: {
    id: null,
    status: "draft",
    promo_type: "promocode",
    technical: 0,
    restaurant: null,
    kitchen_code: "",
    payment_type: "",
    change_from: "",
    type: "soon",
    return_call: "",
    client_comment: "",
    delivered_till: null,
    method: null,
    stock_id: '',
    pickup: 0,
    ignore_stock: 0,
    intercom: "",
    corps: "",
    delivery_type_id: "1",
    client: {
      id: null,
      name: "",
      phone: "",
      source: "",
      sex: null,
      birthday: "",
      comment: "",
    },
    address: {
      city_sync_id: "lviv",
      settlement_id: "",
      street: "",
      house_number: "",
      entrance: "",
      apartment: "",
      floor: "",
      comment: "",
      comment_to_administrator: "",
      comment_to_courier: "",
      latitude: "",
      longitude: "",
    },
    items: [],
    payments: [],
    discounts: [],
    users: {},
    promocode: '',
    persons: '1',
    cutlery_sets: [],
    swappable_bonus_products: [],
    swappable_promo_products: [],
  },
  searchStreet: [],
  isLoadEditClient: false,
  isLoadClient: false,
  isOpenModalReason: false,
  isLoadModalReason: false,
  reason: {
    reason: "",
    comment_reason: "",
    write_off_products: "no",
    duplicate_order: "no",
    scrapped_products: [],
    guilty: "client",
    comment: "",
    kitchen_code: null,
    kitchen_transferring: null,
  },
  reasonData: {
    guilty: [],
    reasons: [],
    write_off_products: [],
    duplicate_order: [],
  },
  check: {},
  ordersAll: [],
  deliveryTime: {
    loading: false,
    time: "-",
  },
  selectedComboBox: {
    slotId: null,
    categories: [],
    root: null,
  },
  additionsMenu: {
    // TBD: maybe use a portal?
    productIndex: null,
    //
    isOpen: false,
    isLoad: false,
    groups: [],
  },
};

export default produce((draft, action) => {
  const { type, payload = {} } = action;
  const item = payload?.['item'];
  const data = payload?.['data'];

  switch (type) {

    case SET_ORDER_DELIVERY_TIME: {
      draft.deliveryTime.time = payload;
      break;
    }

    case SET_ORDER_DELIVERY_TIME_LOADING: {
      draft.deliveryTime.loading = payload;
      break;
    }

    case CLEAN_FORM_ORDER: {
      draft.data = initialState.data;
      break;
    }

    case DELETE_BELONS_PRODUCT: {
      draft.data.items = draft.data.items.filter(el => (payload.findIndex(ite => ite.id == el.id) == -1));
      break;
    }

    case REASON_GET_INFORMATION: {
      draft.reasonData = payload;
      break;
    }

    // ADDITIONS MENU
    case TOGGLE_ADDITIONS_MENU: {
      const { state, productIndex } = payload;

      draft.additionsMenu.productIndex = productIndex;
      draft.additionsMenu.groups = []; // reset

      if (typeof state === 'boolean') {
        // Якщо стан вказано руками, встановимо його
        draft.additionsMenu.isOpen = state;
        break;
      }

      // Інакше, інвертуємо стан
      draft.additionsMenu.isOpen = !draft.additionsMenu.isOpen;
      break;
    }

    case SET_ADDITIONS_MENU_LOADING: {
      draft.additionsMenu.isLoad = payload;
      break;
    }

    case SET_ADDITIONS_MENU_GROUPS: {
      draft.additionsMenu.groups = payload;
      break;
    }

    // CUTLERY
    case REMOVE_CUTLERY_ITEM: {
      draft.data.cutlery_sets.splice(data, 1);
      break;
    }

    case REMOVE_CUTLERY_ALL: {
      draft.data.cutlery_sets = [];
      break;
    }

    case UPDATE_CUTLERY_ITEM: {
      if (draft.data.cutlery_sets.length > 0) {
        let isQuantity = false;
        let index = 0;

        for (let i = 0; i < draft.data.cutlery_sets.length; i++) {
          if (draft.data.cutlery_sets[i].cutlery_set_id == payload.id) {
            isQuantity = true;
            index = i;
            break;
          }
        }

        if (isQuantity) {
          draft.data.cutlery_sets[index][payload.item] = payload.data;
        }
      }

      break;
    }

    case ADD_CUTLERY_ITEM: {
      if (!isArray(draft.data.cutlery_sets) || draft.data.cutlery_sets.length == 0) {
        draft.data.cutlery_sets = [data];
      } else {
        if (draft.data.cutlery_sets.length > 0) {
          let isQuantity = false;
          let index = 0;

          for (let i = 0; i < draft.data.cutlery_sets.length; i++) {
            if (draft.data.cutlery_sets[i].cutlery_set_id == data.cutlery_set_id) {
              isQuantity = true;
              index = i;
              break;
            }
          }

          if (isQuantity) {
            draft.data.cutlery_sets[index].quantity = data.quantity + draft.data.cutlery_sets[index].quantity;
          } else {
            draft.data.cutlery_sets.push(data);
          }
        }
      }

      break;
    }
    // end CUTLERY

    case CLEAR_DATA_CLIENT_ORDER: {
      draft.data.client = initialState.data.client;
      break;
    }

    case REMOVE_ORDER_PROMOCODE: {
      draft.data.discounts.splice(payload, 1);
      break;
    }

    case REMOVE_PROMO_GIFT_DISCOUNT: {
      for (let i = 0; i < draft.data.discounts.length; i++) {
        if (draft.data.discounts[i].discount_type == "promocode") {
          draft.data.discounts.splice(i, 1);
          break;
        }
      }

      break;
    }

    case SET_DISCOUNTS: {
      draft.data.discounts = payload || [];
      break;
    }

    case GET_ORDERS_ALL_CLIETN_ID: {
      draft.ordersAll = payload;
      break;
    }

    case CLEAТ_FORM_CLIENT: {
      draft.data.client = initialState.data.client;
      break;
    }

    case GET_ORDER_PRINT: {
      draft.check = payload;
      break;
    }

    case SET_ORDER_ITEMS: {
      draft.data.items = payload;

      // Можливо, змінився порядок items, або було вибрано продукт з комбо-боксу
      // Очищуємо стейт
      draft.selectedComboBox = cloneDeep(initialState.selectedComboBox);

      break;
    }

    case CLEAN_FORM_REASON: {
      draft.reason = initialState.reason;
      break;
    }

    case ORDER_UPDATE_FORM_REASON_STATUS: {
      draft[item] = data;
      break;
    }

    case ORDER_UPDATE_FORM_REASON: {
      draft.reason[item] = data;
      break;
    }

    case LOAD_CLIENT: {
      draft.isLoadClient = payload;
      break;
    }

    case UPDATE_LOAD_CLIENT: {
      draft.isLoadEditClient = payload;
      break;
    }

    case ADD_ONE_DOP: {
      draft.data.items[payload.indexProduct].additions[payload.indexDop].quantity += 1;
      break;
    }

    case SUBTRACT_ONE_DOP: {
      draft.data.items[payload.indexProduct].additions[payload.indexDop].quantity -= 1;
      break;
    }

    case CLEANING_LIST_STREET: {
      draft.searchStreet = [];
      break;
    }

    case GET_LIST_STREET: {
      draft.searchStreet = payload;
      break;
    }

    case UPDATE_ORDER_DATA: {
      draft.data[item] = data;
      break;
    }

    case UPDATE_ORDER_CLIENT_GET: {
      const { address, ...client } = payload;
      
      draft.data.client = client;
      if (draft.data.pickup != 1) {
        draft.data.address = address || cloneDeep(initialState.data.address);
      }

      break;
    }

    case UPDATE_ORDER_CLIENT: {
      if (!item) {
        draft.data.client.id = data.id;
        draft.data.client.name = data.name;
        draft.data.client.phone = data.phone;
        draft.data.client.source = data.source;
        draft.data.client.wallet = data.wallet;
      } else draft.data.client[item] = data;
      break;
    }

    case UPDATE_ORDER_ADDRESS: {
      draft.data.address[item] = data;
      break;
    }

    case RESET_ORDER_ADDRESS: {
      Object.assign(draft.data.address, {
        street: "",
        house_number: "",
        entrance: "",
        apartment: "",
        floor: "",
        latitude: "",
        longitude: "",
      });
      break;
    }

    // Items

    case UPDATE_ORDER_ITEMS: {
      draft.data.items = data;
      break;
    }

    // можно несколько одинаковых карточек
    case ADD_ORDER_ITEM: {
      draft.data.items.unshift({ ...data, quantity: 1, comment: "" });

      // Можливо, змінився порядок продуктів
      draft.selectedComboBox = cloneDeep(initialState.selectedComboBox);

      break;
    }

    case REMOVE_ORDER_ITEM: {
      const items = lodashGet(draft.data, payload.root);
      const index = items.findIndex((el) => el.id === data.id);
      if (index !== -1) 
        items.splice(index, 1);

      // Можливо, змінився порядок продуктів
      draft.selectedComboBox = cloneDeep(initialState.selectedComboBox);

      break;
    }

    case SELECT_COMBO_BOX: {
      if (isEqual(draft.selectedComboBox, payload)) {
        // Якщо клікають на один і той самий слот, скасовуємо вибір
        draft.selectedComboBox = cloneDeep(initialState.selectedComboBox);
        break;
      }

      draft.selectedComboBox = payload;
      break;
    }

    case OPEN_EDITORCALLCENTER:
    case CLOSE_EDITORCALLCENTER: {
      // Just in case.
      draft.selectedComboBox = cloneDeep(initialState.selectedComboBox);
      break;
    }

    case SELECT_PIZZA_HALF: {
      const { index, half, product } = payload;
      const halfIndex = ({ first_half: 0, second_half: 1 })[half];

      if (index === -1)
        return console.warn('SELECT_PIZZA_HALF: item not found');

      if (halfIndex === undefined)
        return console.warn('SELECT_PIZZA_HALF: unknown half index');

      const halves = draft.data.items[index].halves;
      if (!isArray(halves) || halves.length < 2) {
        draft.data.items[index].halves = Array(2).fill(undefined);
      }

      draft.data.items[index].halves[halfIndex] = product;
      break;
    }

    case SELECT_SWAPPABLE_PRODUCT: {
      const index = draft.data.items.findIndex((el) => el.status === item);
      if (index !== -1) draft.data.items[index] = {
        ...data,
        quantity: 1,
        comment: "",
        status: item,
        is_gift: 1,
      };
      break;
    }

    case UPDATE_ORDER_ITEM_QUANTITY: {
      const index = data;

      if (index !== -1) {
        draft.data.items[index].quantity = payload.quantity;
      }

      break;
    }

    // поиск по index
    case INCREASE_ORDER_ITEM_QUANTITY: {
      const index = data;

      if (index !== -1) {
        draft.data.items[index].quantity += 1;
      }

      break;
    }

    // поиск по index
    case DECREASE_ORDER_ITEM_QUANTITY: {
      const index = data;

      if (index !== -1)
        if (draft.data.items[index].quantity === 1) {
          draft.data.items.splice(index, 1);
        }
        else {
          draft.data.items[index].quantity -= 1;
        }
      break;
    }

    case UPDATE_ORDER_ITEM_COMMENT: {
      // draft.data.items[item].comment = payload.data;
      const itemPath = payload.root + `[${item}]`; // Products root (e.g. items is a top level) + item index inside that root;
      lodashSet(draft.data, `${itemPath}.comment`, payload.data);
      break;
    }

    // Payments
    case UPDATE_ORDER_PAYMENTS: {
      for (const value of draft.data.payments)
        if (value?.payment_type === item) value.sum = data;

      break;
    }

    case UPDATE_ORDER_PAYMENTS_ITEM: {
      for (const value of draft.data.payments)
        if (value?.payment_type === item) {
          let sum = value.sum;
          sum -= data;

          if (sum <= 0) {
            sum = 0;
          }

          value.sum = sum;
        }
      break;
    }

    case ADD_ORDER_PAYMENTS: {
      let isNew = true;
      let i = 0;

      draft.data.payments.map((el, index) => {
        if (el.payment_type === item) {
          isNew = false;
          i = index;
        }
      });

      if (isNew) {
        draft.data.payments.push({ payment_type: item, sum: data, free: payload.free });
      } else {
        draft.data.payments[i].sum = data;
      }

      break;
    }

    case REMOVE_ORDER_PAYMENTS: {
      if (draft.data.payments.length > 1) {
        const index = draft.data.payments.findIndex(
          (value) => value.payment_type === item
        );

        if (index !== -1) draft.data.payments.splice(index, 1);
      } else {
        draft.data.payments = [];
      }

      break;
    }

    case GET_UPDATE_ORDER: {
      draft.data = payload;
      break;
    }

    case UPDATE_ORDER: {
      draft.data = cloneDeep(initialState.data);
      for (const [key, value] of Object.entries(payload)) {
        Object.assign(draft.data[key], value);
      }
      break;
    }

    case RESET_ORDER: {
      draft.data = initialState.data;
      break;
    }

    case GET_KITCHEN_ADDRESS: {
      draft.data.kitchen_code = payload;
      break;
    }

    case GET_ITEM_ORDER_BY_ID: {
      return {
        ...draft,
        ...{
          data: {
            ...draft.data,
            ...payload,
          },
          selectedComboBox: {
            slotId: null,
            categories: [],
            root: null,
          }
        }
      }
      break;
    }

    case GET_SOURCE_CLIENT: {
      draft.data.client.source = payload;
      break;
    }

    case UPDATE_ITEM_DOP_ADD: {
      const { indexProduct, additionId, name, type, prices, numberQuantity } = payload;

      // если нету такого обьекта
      if (!draft.data.items[indexProduct].additions) {
        draft.data.items[indexProduct].additions = [{
          addition_id: additionId,
          quantity: 1,
          type,
          prices,
          addition: {
            name: name,
          }
        }];

        break;
      }

      // найти есть ли уже такое доп.
      const index = draft.data.items[indexProduct].additions.findIndex((el) => el.addition_id === additionId);

      // если есть такой доп увеличить на 1
      if (index !== -1) {
        draft.data.items[indexProduct].additions[index].quantity += 1;
        break;
      }

      // добавить новый доп
      draft.data.items[indexProduct].additions = [...draft.data.items[indexProduct].additions, ...[{
        addition_id: additionId,
        quantity: numberQuantity,
        type,
        prices,
        addition: {
          name: name,
        }
      }]];

      break;
    }

    case UPDATE_DELETE_DOP: {
      const { indexProduct } = payload;

      draft.data.items[indexProduct].additions = [];
      break;
    }

    case UPDATE_ITEM_DELETE: {
      const { indexProduct, additionId } = payload;

      // найти index допа
      const index = draft.data.items[indexProduct].additions.findIndex((el) => el.addition_id === additionId);

      if (index !== -1) {
        draft.data.items[indexProduct].additions.splice(index, 1);
      }

      break;
    }

    default:
      return draft;
  }
}, initialState);
