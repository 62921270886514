import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import { SETTINGS as path } from "constants/routes";

import MenuPage from "components/menuPage";

import styles from "./scss/settings.module.scss";

import { dataItemsMenu } from 'constants/menuPageSettings';

import PageHome from './Home';
import PageGeneral from './General';
import PageSites from './Sites';

const Settings = () => {
    const isRestaurantPickerSites = useRouteMatch(`${path}${dataItemsMenu['sites'].path}`);
    return (
        <div>
            <MenuPage 
              items={dataItemsMenu} 
              isRestaurantPickerSites={isRestaurantPickerSites} 
            />

            <div className={styles.block}>
                <Switch>
                    <Route component={PageHome} exact path={`${path}${dataItemsMenu['common'].path}`} />
                    <Route component={PageGeneral} path={`${path}${dataItemsMenu['general'].path}`} />
                    <Route component={PageSites} path={`${path}${dataItemsMenu['sites'].path}`} />
                </Switch>
            </div>
        </div>
    )
}

export default Settings;