import { connect } from "react-redux";
import { default as SitesRestaurantPicker } from "./SitesRestaurantPicker";

// actions
import { selectSiteSettingsRestaurant } from "store/actions/settings";

const mapStateToProps = (state) => ({
  view: state.view,
  restaurants: state.settings.restaurants,
  selectedRestaurant: state.settings.sites.selectedRestaurant,
});

const mapDispatchToProps = { selectSiteSettingsRestaurant };

export default connect(mapStateToProps, mapDispatchToProps)(SitesRestaurantPicker);
