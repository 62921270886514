import { connect } from 'react-redux';
import { filter } from 'lodash';

import { default as ModalCategories } from './ModalCategories';

// actions
import { updateFormCategories } from 'store/actions/settings';

const mapStateToProps = (state) => ({
  data: state.settings.productCategoriesForm,
  restaurants: state.settings.restaurants,
  categories: filter(state.settings.productCategories, { parent_id: 0 }),
});

const mapDispatchToProps = { 
  updateCategoriesForm: updateFormCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCategories);
