import * as React from "react";

import { find } from "lodash";
import { Stack } from "@mui/material";

// utils
import { getOrderStatusName } from "constants/assignableOrderStatuses";

import classnames from "classnames";
import classes from "./ModalClientInfo.module.scss";

const ItemGroup = ({ title, children, bigTitle = false }) => (
  <div className={classes.group}>
    <legend className={classnames({ [classes.header]: bigTitle })}>
      {title}
    </legend>
    <div className={classes.column}>
      {children}
    </div>
  </div>
);

const ViewButton = ({ label = "Перегляд", status, action }) => (
  <span className={classes.link}>
    <a onClick={() => action(status)}>
      {label}
    </a>
  </span>
);

const OrderItem = ({ article, title, quantity, sum, className }) => (
  <Stack direction="row" justifyContent="space-between" className={className}>
    <Stack direction="row" spacing={1.5}>
      <span>{article}</span>
      <span><b>{title}</b></span>
    </Stack>
    <Stack direction="row" spacing={1.5}>
      <span>{quantity}</span>
      <span style={{ width: "6rem" }}>{sum} грн</span>
    </Stack>
  </Stack>
);

const Order = ({ order }) => (
  <div>
    <span><b>{order.date || order.delivered_till} № {order.id}</b></span>
    <div className={classes.column}>
      {order.items.map((item, index) => (
        <OrderItem
          key={index}
          article={item.product.article}
          title={item.product.title_ua}
          quantity={item.quantity}
          sum={item.sum}
          className={classes.paddedLeft}
        />
      ))}
    </div>
  </div>
);

export const ModalContent = ({
  restaurants,
  data,
  data: {
    statistics = {},
    statistics_orders: statisticsOrders,
    bonuses = {},
    address = {},
    latest_orders: latestOrders = [],
    favorite_items: favoriteItems = [],
    current_order: currentOrder = {},
    delivery_time: deliveryTime = "00 хв.",
  },
  updateClientPartial = () => { },
  getModalClientInfoOrders = () => { },
  updateModalClientInfoData = () => { },
}) => {

  const birthdayRef = React.useRef();
  const phoneRef = React.useRef();

  const restaurantName = React.useMemo(() => {
    return find(restaurants, { code: data.restaurant })?.name;
  }, [restaurants, data.restaurant]);

  const saveClient = React.useCallback(() => {
    const [birthday, phone] = [birthdayRef.current.value, phoneRef.current.value];
    updateClientPartial(data.id, { phone, birthday });
  }, [data.id, updateClientPartial]);

  const viewLatestOrders = React.useCallback(() => {
    updateModalClientInfoData({
      statistics_orders: undefined,
    });
  }, [updateModalClientInfoData]);

  return (
    <div className="cont-modal-info">

      <ItemGroup title={
        <div className={classes.spaceBetween}>
          <span>{data.name} ID: {data.id}</span>
          <span>{restaurantName}</span>
          <span>Час очікування: {deliveryTime}</span>
        </div>
      } bigTitle>
        <div className={classes.container}>

          <ItemGroup title="Інформація про клієнта">
            <Stack spacing={1.5}>
              <span className={classnames(classes.clientRow, classes.spaceBetween)}>
                <b>Дата народження:</b>
                <textarea
                  ref={birthdayRef}
                  rows={1}
                  defaultValue={data.date_of_birth}
                  className={classes.textarea}
                />
              </span>
              <span className={classnames(classes.clientRow, classes.spaceBetween)}>
                <b>Телефон:</b>
                <textarea
                  ref={phoneRef}
                  rows={1}
                  defaultValue={data.phone}
                  className={classes.textarea}
                />
              </span>
              <span>
                <b>
                  м. {address.city_name} {address.street} {address.house_number}
                </b>
              </span>

              <button className={classes.button} onClick={saveClient}>
                Зберегти
              </button>
            </Stack>
          </ItemGroup>

          <ItemGroup title="Статистика клієнта">
            <div className={classes.row}>
              <div className={classnames(classes.column, classes.padless)}>
                <b className={classes.statisticsHeader} style={{ visibility: "hidden" }}>Замовлення</b>
                <Stack spacing={0.5}>
                  <span>
                    <b>Усього замовлень: {statistics.total_orders}</b>
                    <ViewButton action={getModalClientInfoOrders} />
                  </span>
                  <span>
                    <b>Закрито: {statistics.closed}</b>
                    <ViewButton
                      action={getModalClientInfoOrders}
                      status="closed"
                    />
                  </span>
                  <span>
                    <b>Скасовано: {statistics.rejected}</b>
                    <ViewButton
                      action={getModalClientInfoOrders}
                      status="rejected"
                    />
                  </span>
                </Stack>
              </div>
              <div className={classnames(classes.column, classes.padless)}>
                <b className={classes.statisticsHeader}>Бонуси</b>
                <Stack spacing={0.5}>
                  {Object.entries(bonuses).map(([restaurant, amount]) => (
                    <span key={restaurant}>
                      <b>{find(restaurants, { code: restaurant })?.name}: {amount}</b>
                    </span>
                  ))}
                </Stack>
              </div>
            </div>
          </ItemGroup>

          <ItemGroup title="Улюблена страва">
            <Stack spacing={1.5}>
              {favoriteItems.map((item) => (
                <OrderItem
                  key={item.article}
                  article={item.article}
                  title={item.title}
                  quantity={item.total_quantity}
                  sum={item.total_sum}
                />
              ))}
            </Stack>
          </ItemGroup>

          {currentOrder?.id && (
            <div className={classes.flexible}>
              <ItemGroup title={`Замовлення в роботі (${getOrderStatusName(currentOrder.status)})`}>
                <Order order={currentOrder} />
              </ItemGroup>
            </div>
          )}

          <div className={classes.flexible}>
            <ItemGroup title={
              statisticsOrders ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Перегляд замовлень клієнта</span>
                  <ViewButton
                    label="Переглянути останні"
                    action={viewLatestOrders}
                  />
                </div>
              ) : "Останні замовлення"
            }>
              <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                {(statisticsOrders || latestOrders).map((order) => (
                  <Order
                    key={order.id}
                    order={order}
                  />
                ))}
              </div>
            </ItemGroup>
          </div>

        </div>
      </ItemGroup>

    </div>
  );
};
