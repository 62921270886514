import { connect } from "react-redux";

// actions
import { 
  closeModalClientInfo, 
  updateClientPartial,
  getModalClientInfoOrders,
  updateModalClientInfoData,
} from "store/actions/clients";

import ModalClientInfo from './ModalClientInfo';

const mapStateToProps = ({ clients, settings }) => ({
  isOpen: clients.modalClientInfo.isOpen,
  data: clients.modalClientInfo.data,
  restaurants: settings.restaurants,
});

const mapDispatchToProps = {
  closeModalClientInfo,
  updateClientPartial,
  getModalClientInfoOrders,
  updateModalClientInfoData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalClientInfo);
