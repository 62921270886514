import HomeIcon from '@mui/icons-material/Home';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import KitchenIcon from '@mui/icons-material/Kitchen';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AttractionsIcon from '@mui/icons-material/Attractions';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SettingsIcon from '@mui/icons-material/Settings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PaidIcon from '@mui/icons-material/Paid';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DirtyLensIcon from '@mui/icons-material/DirtyLens';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CategoryIcon from '@mui/icons-material/Category';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import BugReportIcon from '@mui/icons-material/BugReport';
import WebSiteIcon from '@mui/icons-material/Language';
import BannersIcon from '@mui/icons-material/ViewCarousel';
import ClientIcon from '@mui/icons-material/AccountCircle';

export const dataItemsMenu = {
    common: {
        title: '',
        path: '',
        icon: <HomeIcon />,
    },
    general: {
        title: 'CRM',
        path: '/general',
        icon: <SettingsIcon />,
    },
    sites: {
        title: 'Сайти',
        path: '/sites',
        icon: <WebSiteIcon />,
    }
}

export const generalDataItemsMenu = {
    common: {
        title: 'Загальна',
        path: '',
        icon: <HomeIcon />,
    },
    restaurants: {
        title: 'Ресторани',
        path: '/restaurants',
        icon: <RestaurantIcon />,
    },
    kitchens: {
        title: 'Кухні',
        path: '/kitchens',
        icon: <KitchenIcon />,
    },
    proving_ground: {
        title: 'Графік роботи і картографія',
        path: '/proving-ground',
        icon: <GroupWorkIcon />,
    },
    locations: {
        title: 'Локації',
        path: '/locations',
        icon: <AddLocationIcon />,
    },
    cities: {
        title: 'Міста',
        path: '/cities',
        icon: <LocationCityIcon />,
    },
    promotions: {
        title: 'Акції',
        path: '/promotions',
        icon: <AttractionsIcon />,
    },
    promocodes: {
        title: 'Промокоди',
        path: '/promocodes',
        icon: <CenterFocusStrongIcon />,
    },
    pickup: {
        title: 'Самовивіз',
        path: '/pickup',
        icon: <HomeWorkIcon />,
    },
    roles: {
        title: 'Ролі',
        path: '/roles',
        icon: <PersonPinIcon />,
    },
    payment: {
        title: 'Оплати',
        path: '/payment',
        icon: <PaidIcon />,
    },
    checks: {
        title: 'Чеки',
        path: '/checks',
        icon: <FactCheckIcon />,
    },
    typesOfDelivery: {
        title: 'Типи доставки',
        path: '/type_of_delivery',
        icon: <DeliveryDiningIcon />,
    },
    blackList: {
        title: 'Чорний список',
        path: '/black_list',
        icon: <FormatIndentDecreaseIcon />,
    },
    sourseForCustomers: {
        title: 'Джерела для клієнтів',
        path: '/sourse_for_customers',
        icon: <AccountTreeIcon />,
    },
    complaints: {
        title: 'Для скарг',
        path: '/complaints',
        icon: <DirtyLensIcon />,
    },
    categories: {
        title: 'Категорії',
        path: '/categories',
        icon: <CategoryIcon />,
    },
    telestat: {
        title: 'Телестат',
        path: '/telestat',
        icon: <PhoneCallbackIcon />,
    },
    bugreports: {
        title: 'Баг-репорти',
        path: '/bugreports',
        icon: <BugReportIcon />,
    }
}

export const generalDataItemsMenuMagazine = {
    common: {
        title: 'Журнал обробка скарг',
        path: '',
        icon: <ImportContactsIcon />,
    },
}

export const generalDataItemsMenuSites = {
    common: {
        title: 'Загальна',
        path: '',
        icon: <HomeIcon />,
    },
    proving_ground: {
      title: 'Графік роботи і картографія',
      path: '/proving-ground',
      icon: <GroupWorkIcon />,
    },
    payment: {
        title: 'Оплати',
        path: '/payment',
        icon: <PaidIcon />,
    },
    clients: {
      title: 'Клієнти',
      path: '/clients',
      icon: <ClientIcon />,
    },
    banners: {
        title: 'Налаштування банерів',
        path: '/banners',
        icon: <BannersIcon />,
    },
    categories: {
        title: 'Налаштування категорій',
        path: '/categories',
        icon: <CategoryIcon />,
    },
}
